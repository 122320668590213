import React, { FC } from 'react';

import DesktopNavigation from '../DesktopNavigation';
import ResponsiveNavigation from '../ResponsiveNavigation';
import { NavigationRendererProps } from './models';

const NavigationRenderer: FC<NavigationRendererProps> = ({
  navigationItems,
  logo,
  logoWhite,
  logoAltText,
  skipToContentText,
  navigationSectionText,
  menuLabel,
  closeMenuLabel,
  searchButtonText,
  closeSearchButtonText,
  locationsButtonText,
  sharePriceLabelText,
  sharePriceLink,
  isDesktop,
  isLargeDesktop,
  headerElement,
  locationsLinks,
}) => {
  const renderDesktopNavigation = (
    <DesktopNavigation
      items={navigationItems}
      logo={logo}
      logoAltText={logoAltText}
      skipToContentText={skipToContentText}
      navigationSectionText={navigationSectionText}
      searchButtonText={searchButtonText}
      closeSearchButtonText={closeSearchButtonText}
      locationsButtonText={locationsButtonText}
      sharePriceLabelText={sharePriceLabelText}
      sharePriceLink={sharePriceLink}
      headerElement={headerElement}
      locationsLinks={locationsLinks}
    />
  );

  const renderResponsiveNavigation = (
    <ResponsiveNavigation
      items={navigationItems}
      logo={logo}
      logoWhite={logoWhite}
      logoAltText={logoAltText}
      locationsButtonText={locationsButtonText}
      sharePriceLabelText={sharePriceLabelText}
      sharePriceLink={sharePriceLink}
      locationsLinks={locationsLinks}
      searchButtonText={searchButtonText}
      menuLabel={menuLabel}
      closeMenuLabel={closeMenuLabel}
    />
  );

  if (isDesktop || isLargeDesktop) return renderDesktopNavigation;

  return renderResponsiveNavigation;
};

export default NavigationRenderer;
