import React, { FC, useRef } from 'react';
import { CommonTypes } from '@shared/types/base/common';

import ExternalLink from 'common/ExternalLink';

import { LocationDropDownProps } from './models';

import './LocationDropDown.scss';

const LocationDropDown: FC<LocationDropDownProps> = ({ locations, locationsCloseHandle }) => {
  const locationRef = useRef<HTMLDivElement>(null);

  const closeLocationHandle = (e) => {
    if (e.target === locationRef.current) return;

    locationsCloseHandle(false);
  };

  const replaceUrlWithCurrentDomain = (item: CommonTypes.LinkType) => ({
    ...item,
    url: item.url.replace('https://www.reckitt.com', window?.location?.origin),
  });

  return (
    <div className="location-drop-down" ref={locationRef}>
      <div
        className="location-drop-down__overlay"
        aria-hidden="true"
        onClick={closeLocationHandle}
      />
      <ul className="location-drop-down__list">
        {locations.map(replaceUrlWithCurrentDomain).map((location) => (
          <li className="location-drop-down__item" key={location.url}>
            <ExternalLink
              className="location-drop-down__link tag"
              url={location.url}
              dataTestId={location.name}
              title={location.name}
            >
              {location.name}
            </ExternalLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LocationDropDown;
