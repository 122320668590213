import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { Link, navigate } from 'gatsby';
import classNames from 'classnames';
import { debounce } from 'lodash';

import { isBrowser } from 'utils/browser';
import { cutUrl } from 'utils/functions';

import HamburgerMenu from '../../../common/icons/hamburger.svg';
import Search from '../../../common/icons/search.svg';
import ModalContext from '../../../contexts/modalContext';
import NavigationBottom from '../NavigationBottom';
import { activeMenusHandle, getNonHiddenElements } from '../utils';
import ListMenuResponsive from './ListMenuResponsive';
import { ResponsiveNavigationProps } from './models';

import './ResponsiveNavigation.scss';

const ResponsiveNavigation: FC<ResponsiveNavigationProps> = ({
  items,
  logo,
  logoWhite,
  logoAltText,
  locationsButtonText,
  sharePriceLabelText,
  sharePriceLink,
  locationsLinks,
  searchButtonText,
  menuLabel,
  closeMenuLabel,
}) => {
  const [activeMenus, setActiveMenus] = useState<string[]>([]);
  const [activeClasses, setActiveClasses] = useState<string[]>([]);
  const [burgerMenuActive, setBurgerMenuActive] = useState<boolean>(false);
  const { isOpen, onOpenModal, onCloseModal } = useContext(ModalContext);
  const [currentDepth, setCurrentDepth] = useState(0);
  const firstLevelRef = useRef<HTMLDivElement>(null);

  const url = cutUrl(logo.url);
  const urlLogoWhite = cutUrl(logoWhite.url);

  const itemClickHandle = (menuName, depth) => {
    if (firstLevelRef && firstLevelRef.current) firstLevelRef.current.scrollTop = 0;

    if (depth === 2) {
      const secondLevel = document.querySelectorAll('.nav-responsive__list-wraper--second')[0];

      secondLevel.scrollTop = 0;
    }

    setCurrentDepth(depth);

    let newActiveMenus = [...activeMenus];
    setActiveClasses(newActiveMenus);

    newActiveMenus = activeMenusHandle(newActiveMenus, menuName, depth);
    setActiveMenus(newActiveMenus);
  };

  const backHandle = () => {
    currentDepth === 2 ? setCurrentDepth(1) : setCurrentDepth(0);

    const newActiveMenus = [...activeMenus];
    newActiveMenus.pop();
    setActiveMenus(newActiveMenus);
    setActiveClasses(newActiveMenus);
  };

  useEffect(() => {
    burgerMenuActive || isOpen
      ? document.body.classList.add('no-scroll')
      : document.body.classList.remove('no-scroll');
  }, [burgerMenuActive]);

  const closeBurgerMenuHandle = () => {
    setCurrentDepth(0);
    setBurgerMenuActive(false);
    setActiveMenus([]);
    setActiveClasses([]);
  };

  const activePageClickHandle = (e, path) => {
    if (e.currentTarget.href === window.location.href) {
      setActiveMenus([]);
      setActiveClasses([]);
      setBurgerMenuActive(false);
    } else {
      navigate(path);
    }
  };

  const handleResize = debounce(() => {
    if (isBrowser()) {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    }
  }, 100);

  useEffect(() => {
    if (isBrowser()) {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div
      className={classNames('header-responsive__container', {
        'header-responsive__container--search-open': isOpen,
      })}
      data-testid="navigation"
    >
      <div className="header-responsive__top">
        <div className="header-responsive__top-link">
          <Link className="header-responsive__logo-link" to="/">
            {!isOpen ? (
              <img src={url} alt={logoAltText} className="header-responsive__logo" />
            ) : (
              <img src={urlLogoWhite} alt={logoAltText} className="header-responsive__logo" />
            )}
          </Link>
        </div>
        <div className="header__top-btns">
          {!isOpen ? (
            <button
              type="button"
              className="header-responsive__search-btn"
              aria-label={searchButtonText}
              aria-expanded={isOpen}
              aria-controls="modal"
              onClick={() => {
                window.scrollTo(0, 0);
                closeBurgerMenuHandle();
                onOpenModal();
                document.body.classList.add('no-scroll');
              }}
            >
              <Search
                className="header-responsive__search-pic"
                focusable="false"
                aria-hidden="true"
              />
            </button>
          ) : null}
          {!burgerMenuActive && (
            <button
              type="button"
              className="header-responsive__menu-btn"
              aria-label={menuLabel}
              aria-expanded={isOpen}
              aria-controls="modal"
              onClick={() => {
                window.scrollTo(0, 0);
                setBurgerMenuActive(true);
                onCloseModal();
              }}
            >
              <HamburgerMenu
                className="header-responsive__search-pic"
                focusable="false"
                aria-hidden="true"
              />
            </button>
          )}
          {burgerMenuActive && (
            <button
              type="button"
              className="header-responsive__close-btn"
              aria-label={closeMenuLabel}
              onClick={closeBurgerMenuHandle}
            />
          )}
        </div>
      </div>
      {burgerMenuActive && (
        <div
          className={classNames('header-responsive__collapse', {
            'header-responsive__collapse--second': currentDepth === 1,
            'header-responsive__collapse--third': currentDepth === 2,
          })}
          ref={firstLevelRef}
        >
          <nav className="nav-responsive" role="navigation">
            <ul
              className="nav-responsive__list nav-responsive__list--first"
              data-testid="responsive-navigation"
            >
              {getNonHiddenElements(items).map((menu, index) => {
                const depth = 1;
                const menuName = `sidebar-menu-${depth}-${index}`;

                return (
                  <ListMenuResponsive
                    activeClasses={activeClasses}
                    depth={depth}
                    data={menu}
                    hasSubMenu={menu.childElements?.length > 0}
                    menuName={menuName}
                    key={menuName}
                    menuIndex={index}
                    activeMenus={activeMenus}
                    itemClickHandle={itemClickHandle}
                    activePageClickHandle={activePageClickHandle}
                    backHandle={backHandle}
                    sharePriceLabelText={sharePriceLabelText}
                    sharePriceLink={sharePriceLink}
                    locationsButtonText={locationsButtonText}
                    locationsLinks={locationsLinks}
                  />
                );
              })}
            </ul>
          </nav>
          {activeMenus.length === 0 && (
            <NavigationBottom
              isResponsive
              locationsButtonText={locationsButtonText}
              sharePriceLabelText={sharePriceLabelText}
              sharePriceLink={sharePriceLink}
              locationsLinks={locationsLinks}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ResponsiveNavigation;
