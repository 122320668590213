export const handleChangeAtribute = (e, className, activeClassName) => {
  const all = document.querySelectorAll(`.${className}`);
  const ariaExpanded = e.currentTarget.getAttribute('aria-expanded');
  const value = ariaExpanded === 'false' ? 'true' : 'false';
  all.forEach((element) => {
    if (element.classList.contains(activeClassName)) return;
    element.setAttribute('aria-expanded', 'false');
  });
  e.currentTarget.setAttribute('aria-expanded', value);
};
