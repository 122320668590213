import React, { FC, memo } from 'react';

import AlgoliaSearchBox from './AlgoliaSearchBox';
import { SearchInputProps } from './models';
import SearchBoxComponent from './SearchBoxComponent';

import './SearchInput.scss';

const SearchInput: FC<SearchInputProps> = ({
  withAlgolia,
  searchPlaceholder,
  searchButtonText,
}) => (
  <div className="search-input__container">
    <form role="search" className="search-input__form">
      {withAlgolia ? (
        <AlgoliaSearchBox
          searchPlaceholder={searchPlaceholder}
          searchButtonText={searchButtonText}
        />
      ) : (
        <SearchBoxComponent
          searchPlaceholder={searchPlaceholder}
          searchButtonText={searchButtonText}
        />
      )}
    </form>
  </div>
);

export default memo(SearchInput);
