const isEmpty = (obj): boolean => {
  if (obj === undefined || obj === null) {
    return true;
  }
  if (Array.isArray(obj)) {
    return !obj.length;
  }
  if (typeof obj === 'object') {
    return !Object.keys(obj).length;
  }

  return !obj;
};

export const handleSkipSection = (sectionRef) => {
  sectionRef.current?.nextElementSibling?.scrollIntoView({ behavior: 'smooth' });

  const nextFocusableElement = (
    sectionRef.current?.nextElementSibling as HTMLElement
  )?.querySelectorAll('a, button')[0] as HTMLButtonElement | HTMLLinkElement;

  if (nextFocusableElement) {
    nextFocusableElement.focus();
  }

  if (!sectionRef.current?.nextElementSibling) {
    const footer = document.querySelector('.footer');
    footer!.scrollIntoView({ behavior: 'smooth' });

    footer?.querySelectorAll('a')[0].focus();
  }
};

export const handleSkipSectionPrevious = (sectionRef) => {
  sectionRef.current?.previousElementSibling?.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
    inline: 'nearest',
  });

  const previousFocusableElement = (
    sectionRef.current?.previousElementSibling as HTMLElement
  )?.querySelectorAll('a, button')[0] as HTMLButtonElement | HTMLLinkElement;

  if (previousFocusableElement) {
    previousFocusableElement.focus();
  }

  if (!sectionRef.current?.previousElementSibling) {
    const footer = document.querySelector('.footer');
    footer!.scrollIntoView({ behavior: 'smooth' });

    footer?.querySelectorAll('a')[0].focus();
  }
};

export const cutUrl = (url: string): string => `/assets/${url.split('/').slice(4).join('/')}`;

export default isEmpty;
