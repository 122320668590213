import React, { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { graphql, useStaticQuery } from 'gatsby';
import FocusTrap from 'focus-trap-react';
import { AnimatePresence, motion } from 'framer-motion';

import { Col, Container, Row } from 'layout';
import SearchInput from 'common/SearchInput';
import PopularSearchTerms from 'components/PopularSearchTerms';

import Close from '../../common/icons/clear-btn.svg';
import Pause from '../../common/icons/pause-brands.svg';
import Play from '../../common/icons/play.svg';
import ModalContext from '../../contexts/modalContext';
import ScreenRecognitionContext from '../../contexts/screenRecognitionContext';
import { SearchModalProps } from './models';

import './SearchModal.scss';
import '../PopularTopics/PopularTopics.scss';

const SearchModal = () => {
  const { isDesktop, isLargeDesktop } = useContext(ScreenRecognitionContext);
  const { searchPopup }: SearchModalProps = useStaticQuery(graphql`
    query SearchPopupQuery {
      searchPopup {
        title
        closeButtonText
        searchPlaceholder
        searchButtonText
        popularSearchTermsTitle
        popularSearchTermsButtonText
        popularSearchTerms {
          term
        }
      }
    }
  `);

  const {
    title,
    closeButtonText,
    searchPlaceholder,
    searchButtonText,
    popularSearchTermsTitle,
    popularSearchTermsButtonText,
    popularSearchTerms,
  } = searchPopup;

  const [domReady, setDomReady] = useState(false);
  const [animationPaused, setAnimationPaused] = useState(false);
  const { isOpen, onCloseModal } = useContext(ModalContext);

  const pauseAnimation = () => {
    const animationHolder = document.querySelector('.search-modal__holder') as HTMLElement;

    if (!animationHolder) return;

    setAnimationPaused(!animationPaused);

    animationPaused
      ? (animationHolder.style.animationPlayState = 'running')
      : (animationHolder.style.animationPlayState = 'paused');
  };

  function ModalContainer() {
    return (
      <div className="search-modal__focus-container">
        <button
          type="button"
          className="search-modal__pause-animation"
          aria-label="Pause animation"
          onClick={() => {
            pauseAnimation();
          }}
        >
          <span className="search-modal__pause-animation-text" aria-hidden="true">
            {isOpen ? (animationPaused ? 'Play animation' : 'Pause animation') : 'Pause animation'}
          </span>
          <span className="search-modal__pause-animation-ico">
            {animationPaused ? (
              <Play focusable="false" aria-hidden="true" />
            ) : (
              <Pause focusable="false" aria-hidden="true" />
            )}
          </span>
        </button>
        <button
          type="button"
          className="search-modal__responsive-close-btn"
          aria-label={closeButtonText}
          onClick={() => {
            onCloseModal();
            document.body.classList.remove('no-scroll');
          }}
        />
        <button
          type="button"
          className="search-modal__close-btn"
          aria-label={closeButtonText}
          onClick={() => {
            onCloseModal();
            document.body.classList.remove('no-scroll');
            setAnimationPaused(false);
          }}
        >
          <span className="search-modal__close-btn-text" aria-hidden="true">
            {closeButtonText}
          </span>
          <span className="search-modal__close-btn-ico">
            <Close focusable="false" aria-hidden="true" />
          </span>
        </button>
        <Row>
          <Col xl="10" className="col--no-gutters">
            <div className="search-modal__content">
              <h2 className="search-modal__title h1">{title}</h2>
              <SearchInput
                searchPlaceholder={searchPlaceholder}
                searchButtonText={searchButtonText}
              />
              <PopularSearchTerms
                popularSearchTerms={popularSearchTerms}
                popularSearchTermsTitle={popularSearchTermsTitle}
                popularSearchTermsButtonText={popularSearchTermsButtonText}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  const handleEscape = (e): void => {
    if (e.key === 'Escape') {
      onCloseModal();
      document.body.classList.remove('no-scroll');
    }
  };

  useEffect(() => {
    setDomReady(true);

    document.addEventListener('keydown', handleEscape);

    return () => document.removeEventListener('keydown', handleEscape);
  }, []);

  return domReady
    ? ReactDOM.createPortal(
        <AnimatePresence>
          {isOpen ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              className="search-modal search-modal--active"
              role="dialog"
              aria-label="Search modal"
            >
              <div className="search-modal__holder">
                <Container>
                  {isDesktop || isLargeDesktop ? (
                    <FocusTrap
                      focusTrapOptions={{
                        initialFocus: '.search-input__input-modal',
                        allowOutsideClick: () => true,
                      }}
                    >
                      <div className="focus-trap-desktop">
                        <ModalContainer />
                      </div>
                    </FocusTrap>
                  ) : (
                    <FocusTrap
                      focusTrapOptions={{
                        allowOutsideClick: () => true,
                      }}
                    >
                      <div className="focus-trap-mobile">
                        <ModalContainer />
                      </div>
                    </FocusTrap>
                  )}
                </Container>
              </div>
            </motion.div>
          ) : null}
        </AnimatePresence>,
        document.getElementById('modal')!
      )
    : null;
};

export default SearchModal;
