import React, { FC, useContext } from 'react';
import { navigate } from '@reach/router';

import { Col, Row } from 'layout';
import Arrow from 'common/icons/arrow-right.svg';
import CustomCarousel from 'components/CustomCarousel';
import { POPULAR_TOPICS_TABLET } from 'utils/constants';

import ModalContext from '../../contexts/modalContext';
import ScreenRecognitionContext from '../../contexts/screenRecognitionContext';
import { PopularSearchTermsProps } from './models';

import './PopularSearchTerms.scss';

const PopularSearchTerms: FC<PopularSearchTermsProps> = ({
  popularSearchTerms,
  popularSearchTermsTitle,
  popularSearchTermsButtonText,
}) => {
  const { isTablet, isMobile, isSmallMobile } = useContext(ScreenRecognitionContext);
  const { onCloseModal } = useContext(ModalContext);

  const goToSearchPage = (searchQuery) => {
    navigate(encodeURI(`/search/?q=${searchQuery}`));
  };

  const cards = popularSearchTerms?.map((item, i) =>
    i === POPULAR_TOPICS_TABLET && isTablet ? null : (
      <Col className="popular-topics__col col--no-gutters" key={item.term} xl="3" md="4">
        <div className="popular-topics__card">
          <h3 className="popular-topics__card-title h6">{item.term}</h3>
          <button
            type="button"
            className="popular-topics__cta link link--black-white"
            onClick={() => {
              goToSearchPage(item.term);
              onCloseModal();
            }}
          >
            {popularSearchTermsButtonText}
            <Arrow aria-hidden="true" />
          </button>
        </div>
      </Col>
    )
  );

  const carousel = (
    <CustomCarousel autoPlay infiniteLoop showArrows={false} showStatus={false} showThumbs={false}>
      {popularSearchTerms?.map((item) => (
        <div className="popular-topics__card" key={item.term}>
          <h3 className="popular-topics__card-title h6">{item.term}</h3>
          <button
            type="button"
            className="popular-topics__cta link link--black-white"
            onClick={() => {
              goToSearchPage(item.term);
              onCloseModal();
            }}
          >
            {popularSearchTermsButtonText}
            <Arrow aria-hidden="true" />
          </button>
        </div>
      ))}
    </CustomCarousel>
  );

  return (
    <div className="popular-search-terms">
      <div className="popular-topics">
        <Row>
          {popularSearchTermsTitle ? (
            <Col className="col--no-gutters">
              <h2 className="popular-topics__title h5">{popularSearchTermsTitle}</h2>
            </Col>
          ) : null}
        </Row>
        <Row>{isMobile || isSmallMobile ? carousel : cards}</Row>
      </div>
    </div>
  );
};

export default PopularSearchTerms;
