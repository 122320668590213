import { NavigationElement } from './models';

export const activeMenusHandle = (
  newActiveMenus: string[],
  menuName: string,
  depth: number
): string[] => {
  if (
    !newActiveMenus.includes(menuName) &&
    newActiveMenus.some((menu) => menu.includes(`menu-${depth}`))
  ) {
    const filteredMenus = newActiveMenus.filter(
      (menu) => menu.includes(`menu-${depth}`) || menu.includes(`menu-${depth + 1}`)
    );
    filteredMenus.forEach((menu) => newActiveMenus.splice(newActiveMenus.indexOf(menu), 1));
  }

  if (newActiveMenus.includes(menuName)) {
    const index = newActiveMenus.indexOf(menuName);
    if (index > -1) {
      newActiveMenus.splice(index, 1);
    }
  } else {
    newActiveMenus.push(menuName);
  }

  return newActiveMenus;
};

export const getNonHiddenElements = (data: NavigationElement[]): NavigationElement[] =>
  data.filter((menu) => menu.hideFromNavigation === '0');

export const getItemClassesByDepth = (depth: number): string => {
  switch (depth) {
    case 1:
      return 'nav__item nav__item--first tag';
    case 2:
      return 'nav__item nav__item--second tag';
    case 3:
      return 'nav__item nav__item--third tag';
    default:
      return 'nav__item tag';
  }
};

const getActiveLinkClassesIncludedName = (depth: number): string => {
  const clickedClass = 'active-link-clicked nav__link tag';
  switch (depth) {
    case 1:
      return `${clickedClass} nav__link--first`;
    case 2:
      return `${clickedClass} nav__link--second`;
    case 3:
      return '';
    default:
      return `${clickedClass} nav__link--third`;
  }
};

const getActiveClassesExcludedName = (depth: number): string => {
  switch (depth) {
    case 1:
      return 'nav__link tag nav__link--first';
    case 2:
      return 'nav__link tag nav__link--second';
    case 3:
      return 'nav__link tag nav__link--third';
    default:
      return 'nav__link tag';
  }
};

export const getActiveLinkClassesByDepth = (
  activeClasses: string[],
  menuName: string,
  depth: number
): string => {
  if (activeClasses.includes(menuName)) {
    return getActiveLinkClassesIncludedName(depth);
  }

  return getActiveClassesExcludedName(depth);
};

export const getLinkClassesByDepth = (depth: number): string => {
  switch (depth) {
    case 1:
      return 'nav__link tag nav__link--first';
    case 2:
      return 'nav__link tag nav__link--second';
    case 3:
      return 'nav__link tag nav__link--third';
    default:
      return 'nav__link tag';
  }
};

export const getResponsiveItemClassesByDepth = (depth: number): string => {
  switch (depth) {
    case 1:
      return 'nav-responsive__item nav-responsive__item--first tag';
    case 2:
      return 'nav-responsive__item nav-responsive__item--second tag';
    case 3:
      return 'nav-responsive__item nav-responsive__item--third tag';
    default:
      return '';
  }
};

export const getResponsiveLinkClassesByDepth = (depth: number): string => {
  switch (depth) {
    case 2:
      return 'nav-responsive__link nav-responsive__link--second';
    case 3:
      return 'nav-responsive__link nav-responsive__link--third';
    default:
      return 'nav-responsive__link';
  }
};

export const getResponsiveActiveLinkClassesByDepth = (
  activeClasses: string[],
  menuName: string,
  depth: number
): string => {
  if (activeClasses.includes(menuName)) {
    return 'active-link-clicked nav-responsive__link';
  }

  return getResponsiveLinkClassesByDepth(depth);
};

export const constants = {
  firstLink: '.nav__link--first',
  secondLink: '.nav__link--second',
  thirdLink: '.nav__link--third',
};
