import React, { FC, useEffect, useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import loadable from '@loadable/component';
import classNames from 'classnames';
import useScreenRecognition from 'hooks/useScreenRecognition';
import { debounce } from 'lodash';

import 'styles/main.scss';
import './Layout.scss';

import Footer from 'components/Footer';
import Loadable from 'components/Loadable';
import Navigation from 'components/Navigation';
import SearchModal from 'components/SearchModal';
import { isBrowser } from 'utils/browser';

import { AlgoliaContextProvider } from '../../contexts/algoliaContext';
import LanguageContext from '../../contexts/languageContext';
import { ModalContextProvider } from '../../contexts/modalContext';
import ScreenRecognitionContext from '../../contexts/screenRecognitionContext';
import { SharePriceContextProvider } from '../../contexts/sharePriceContext';
import { LayoutProps } from './models';

import 'components/FontawesomeIcons';

const PreviewWidget = loadable(() => import('components/PreviewWidget'));

const Layout: FC<LayoutProps> = ({ children, headerTransparent, className }) => {
  const { siteSettings } = useStaticQuery(graphql`
    query {
      siteSettings {
        lang
      }
    }
  `);

  const { lang } = siteSettings;
  const screenResolution = useScreenRecognition();
  const screenResolutionProviderValues = useMemo(() => ({ screenResolution }), [screenResolution]);

  const CSSclassNames = classNames('layout', className, {
    'layout--transparent': headerTransparent,
  });

  const shouldLoadPreviewWidget = Boolean(Number(process.env.GATSBY_ENABLE_PREVIEW_WIDGET));

  const setViewHeight = () => {
    if (isBrowser()) {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    }
  };

  const handleResize = debounce(() => {
    setViewHeight();
  }, 100);

  useEffect(() => {
    setViewHeight();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <ScreenRecognitionContext.Provider value={screenResolutionProviderValues.screenResolution}>
      <LanguageContext.Provider value={lang}>
        <ModalContextProvider>
          <AlgoliaContextProvider>
            <SharePriceContextProvider>
              <div className={CSSclassNames}>
                <Loadable isActive={shouldLoadPreviewWidget}>
                  <PreviewWidget cmsUrl={`${process.env.GATSBY_UMBRACO_BASE_URL}/umbraco`} />
                </Loadable>
                <Navigation />
                <SearchModal />

                <main id="main" className="layout__main" tabIndex={-1}>
                  {children}
                </main>

                <Footer />
              </div>
              <div id="modal" />
            </SharePriceContextProvider>
          </AlgoliaContextProvider>
        </ModalContextProvider>
      </LanguageContext.Provider>
    </ScreenRecognitionContext.Provider>
  );
};

export default Layout;
