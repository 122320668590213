import React, { FC } from 'react';

import { ExternalLinkProps } from './models';

const ExternalLink: FC<ExternalLinkProps> = ({ className, url, dataTestId, title, children }) => (
  <a
    className={className}
    href={url}
    target="_blank"
    rel="noreferrer noopener"
    data-testid={dataTestId}
    title={title}
  >
    {children}
  </a>
);

export default ExternalLink;
