import React, { createContext, useCallback, useState } from 'react';

const ModalContext = createContext({
  isOpen: false,
  onOpenModal: () => {},
  onCloseModal: () => {},
});

export const ModalContextProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModalHandler = useCallback(() => {
    document.body.classList.add('no-scroll');
    setIsModalOpen(true);
  }, []);

  const closeModalHandler = useCallback(() => {
    document.body.classList.remove('no-scroll');
    setIsModalOpen(false);
  }, []);

  return (
    <ModalContext.Provider
      value={{
        isOpen: isModalOpen,
        onOpenModal: openModalHandler,
        onCloseModal: closeModalHandler,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export default ModalContext;
