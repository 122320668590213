import { useEffect, useState } from 'react';

import {
  DESKTOP_BREAKPOINT,
  LARGE_DESKTOP,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
} from './constants';
import { UseScreenRecognitionHook } from './model';

const useScreenRecognition: UseScreenRecognitionHook = () => {
  const [isSmallMobile, setIsSmallMobile] = useState<null | boolean>(null);
  const [isMobile, setIsMobile] = useState<null | boolean>(null);
  const [isTablet, setIsTablet] = useState<null | boolean>(null);
  const [isDesktop, setIsDesktop] = useState<null | boolean>(null);
  const [isLargeDesktop, setIsLargeDesktop] = useState<null | boolean>(null);

  const handleResize = () => {
    const width = window && window.innerWidth;
    setIsSmallMobile(width < MOBILE_BREAKPOINT);
    setIsMobile(width >= MOBILE_BREAKPOINT && width < TABLET_BREAKPOINT);
    setIsTablet(width >= TABLET_BREAKPOINT && width < DESKTOP_BREAKPOINT);
    setIsDesktop(width >= DESKTOP_BREAKPOINT && width < LARGE_DESKTOP);
    setIsLargeDesktop(width >= LARGE_DESKTOP);
  };

  useEffect(() => {
    handleResize();
    window && window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    isSmallMobile,
    isMobile,
    isTablet,
    isDesktop,
    isLargeDesktop,
  };
};

export default useScreenRecognition;
