import React, { FC, useEffect, useRef, useState } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import { penniesToPounds } from 'utils/services/sharePriceService';

import Location from '../../../common/icons/location.svg';
import { useSharePriceContext } from '../../../contexts/sharePriceContext';
import LocationDropDown from './LocationDropDown';
import { NavigationBottomProps } from './models';

const NavigationBottom: FC<NavigationBottomProps> = ({
  isResponsive,
  locationsButtonText,
  sharePriceLabelText,
  sharePriceLink,
  locationsLinks,
}) => {
  const [isLocationsOpen, setIsLocationsOpen] = useState<boolean>(false);
  const [isPlusSign, setIsPlusSign] = useState<boolean>(false);
  const locationRef = useRef<HTMLDivElement>(null);

  const responsiveModifier: string = isResponsive ? '-responsive' : '';

  const sharePriceData = useSharePriceContext();

  const locationsOpenHandle = () => {
    setIsLocationsOpen((prev) => !prev);
  };

  const locationsCloseHandle = (close) => {
    setIsLocationsOpen(close);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isLocationsOpen && locationRef.current && !locationRef.current.contains(e.target)) {
        setIsLocationsOpen(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isLocationsOpen]);

  useEffect(() => {
    const changeSign = sharePriceData.data?.changeSign;
    if (!changeSign) return;
    setIsPlusSign(changeSign === '+');
  }, [sharePriceData]);

  return (
    <div className={`header${responsiveModifier}__bottom`} data-testid="navigation-bottom">
      <div className={`header${responsiveModifier}__location-wrap`} ref={locationRef}>
        <button
          type="button"
          className={classNames(`header${responsiveModifier}__location`, {
            [`header${responsiveModifier}__location--active`]: isLocationsOpen,
          })}
          onClick={() => {
            locationsOpenHandle();
          }}
        >
          <span className={`header${responsiveModifier}__location-icon`} aria-hidden="true">
            <Location
              className={`header${responsiveModifier}__location-pic`}
              alt="Decorative icon"
            />
          </span>
          <span className={`header${responsiveModifier}__location-text`}>
            {locationsButtonText}
          </span>
        </button>
        {isLocationsOpen ? (
          <LocationDropDown
            locations={locationsLinks}
            locationsCloseHandle={locationsCloseHandle}
          />
        ) : null}
      </div>
      {!!sharePriceData.data && Object.keys(sharePriceData.data).length ? (
        <Link
          className={`header${responsiveModifier}__share-price`}
          to={sharePriceLink[0].url}
          title="Share price"
          data-testid="share-price"
        >
          <span className="header__share-price-span breadcrumb">{sharePriceLabelText}</span>
          <span className={`header${responsiveModifier}__share-price-number tag`}>
            <sup>&#163;</sup>
            {penniesToPounds(sharePriceData.data.currentPrice)}
          </span>
          <div className={`header${responsiveModifier}__share-price-text`}>
            {`${isPlusSign ? sharePriceData.data.changeSign : ''}${penniesToPounds(
              sharePriceData.data.change
            )} ${sharePriceData.data.percentageChange}%`}
          </div>
        </Link>
      ) : (
        <div className="header__spinner" />
      )}
    </div>
  );
};

export default NavigationBottom;
