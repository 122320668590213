import React, { FC } from 'react';
import { Link } from 'gatsby';

import { getNonHiddenElements } from 'components/Navigation/utils';

import Arrow from '../../../../common/icons/arrow-right.svg';
import ListMenu from '../ListMenu';
import { SubMenuProps } from './models';

import './SubMenu.scss';

const SubMenu: FC<SubMenuProps> = ({
  navigationSectionText,
  depth,
  data,
  toggle,
  menuIndex,
  mainPage,
  activeMenus,
  activeClasses,
  itemClickHandle,
  activePageClickHandle,
  styleSecondVerticalLine,
  styleThirdVerticalLine,
  focusHandle,
  onMouseOverHandle,
}) => {
  if (!toggle) {
    return null;
  }

  const depthVar = depth + 1;

  return (
    <div
      className={
        depthVar === 2
          ? 'nav__list-wraper nav__list-wraper--second'
          : 'nav__list-wraper nav__list-wraper--third'
      }
    >
      {depthVar === 2 && (
        <span
          className="header__second-vertical-line"
          aria-hidden="true"
          style={styleSecondVerticalLine}
        />
      )}
      {depthVar === 3 && (
        <span
          className="header__third-vertical-line"
          aria-hidden="true"
          style={styleThirdVerticalLine}
        />
      )}
      <ul
        className={depthVar === 2 ? 'nav__list nav__list--second' : 'nav__list nav__list--third'}
        data-testid="sub-menu"
      >
        {depthVar !== 1 && (
          <li className="nav__section">
            <p className="nav__section-name breadcrumb">{navigationSectionText}</p>
            <Link
              className="nav__title-list-link"
              to={mainPage?.page[0].url}
              onClick={(e) => {
                e.preventDefault();
                activePageClickHandle(e, mainPage?.page[0].url);
              }}
            >
              <span className="nav__title-list-title">{mainPage?.page[0].name}</span>
              <span className="nav__arrow" aria-hidden="true">
                <Arrow className="nav__arrow-pic" alt="Decorative icon" />
              </span>
            </Link>
          </li>
        )}
        {getNonHiddenElements(data).map((menu, index) => {
          const menuName = `sidebar-submenu-${depthVar}-${menuIndex}-${index}`;

          return (
            <ListMenu
              navigationSectionText={navigationSectionText}
              activeClasses={activeClasses}
              depth={depthVar}
              data={menu}
              hasSubMenu={menu.childElements?.length > 0}
              menuName={menuName}
              key={menuName}
              menuIndex={index}
              activeMenus={activeMenus}
              itemClickHandle={itemClickHandle}
              activePageClickHandle={activePageClickHandle}
              styleSecondVerticalLine={styleSecondVerticalLine}
              styleThirdVerticalLine={styleThirdVerticalLine}
              focusHandle={focusHandle}
              onMouseOverHandle={onMouseOverHandle}
              parentPage={mainPage?.page[0].name}
            />
          );
        })}
      </ul>
    </div>
  );
};

export default SubMenu;
