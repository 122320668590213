import React, { FC, useEffect, useState } from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';

import { getQueryStringVal, setQueryStringVal } from 'utils/useQueryParam';

import Search from '../icons/search.svg';
import { AlgoliaSearchProps } from './models';

const SearchBoxComponent: FC<AlgoliaSearchProps> = ({
  refine,
  currentRefinement,
  searchPlaceholder,
  searchButtonText,
}) => {
  const [inputValue, setInputValue] = useState<string>(currentRefinement);

  const refineFunction = (valueToRefine) => {
    const trimValue = valueToRefine.trim();
    if (trimValue === '') return;
    refine(trimValue);
    setQueryStringVal('q', encodeURI(trimValue));
  };

  const curValue = getQueryStringVal('q');

  useEffect(() => {
    setInputValue(curValue);
    refine(curValue);
  }, [curValue]);

  const handleSearch = (e): void => {
    e.preventDefault();
    inputValue && refineFunction(inputValue);
  };

  const handleKeyPress = (e): void => {
    if (e.key === 'Enter') {
      handleSearch(e);
    }
  };

  const handleChangeInput = (e): void => setInputValue(e.target.value);

  return (
    <>
      <input
        type="search"
        className="search-input__input"
        placeholder={searchPlaceholder}
        aria-label="Search"
        value={inputValue}
        onChange={handleChangeInput}
        onKeyPress={handleKeyPress}
      />
      <button
        type="submit"
        className="search-input__btn"
        onClick={handleSearch}
        aria-label={searchButtonText}
      >
        <span className="search-input__btn-text" aria-hidden="true">
          {searchButtonText}
        </span>
        <span className="search-input__btn-icon">
          <Search aria-hidden="true" focusable="false" className="search-input__btn-pic" />
        </span>
      </button>
    </>
  );
};

const AlgoliaSearchBox = connectSearchBox(SearchBoxComponent);

export default AlgoliaSearchBox;
