import React, { FC, useEffect, useRef, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { graphql, useStaticQuery } from 'gatsby';

import { CustomCarouselProps } from './models';

const CustomCarousel: FC<CustomCarouselProps> = (props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const {
    commonFields: { sliderPrevButtonText, sliderNextButtonText },
  } = useStaticQuery(graphql`
    {
      commonFields {
        sliderPrevButtonText
        sliderNextButtonText
      }
    }
  `);

  const [currentSlide, setCurrentSlide] = useState(props.selectedItem || 0);

  const next = () => {
    setCurrentSlide((prevState) => prevState + 1);
  };

  const prev = () => {
    setCurrentSlide((prevState) => prevState - 1);
  };

  const disableTabbing = () => {
    if (!containerRef.current) return;

    const slides = containerRef.current.querySelectorAll<HTMLElement>('.slide');
    slides.forEach((slide) => {
      slide.querySelectorAll('a, button').forEach((elem) => {
        elem.setAttribute('tabindex', '-1');
      });
    });
  };

  const enableTabbing = () => {
    if (!containerRef.current) return;

    const wrapperPosition = containerRef.current.getBoundingClientRect().left;
    const slides = containerRef.current.querySelectorAll<HTMLElement>('.slide');
    slides.forEach((slide) => {
      if (slide.getBoundingClientRect().left === wrapperPosition) {
        slide.querySelectorAll('a, button').forEach((elem) => {
          elem.setAttribute('tabindex', '0');
        });
        slide.querySelectorAll('iframe').forEach((elem) => {
          elem.style.visibility = 'visible';
        });
      }
    });
  };

  useEffect(() => {
    disableTabbing();
    enableTabbing();
  }, []);

  const updateCurrentSlide = (index) => {
    if (containerRef.current) {
      disableTabbing();
      setTimeout(() => {
        enableTabbing();
      }, 500);
    }

    if (props.onChange) props.onChange(index);
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  useEffect(() => {
    setCurrentSlide(props.selectedItem || 0);
  }, [props.selectedItem]);

  return (
    <div className="carousel-container" ref={containerRef} role="group" aria-label="Carousel">
      <Carousel
        {...props}
        stopOnHover={false}
        swipeScrollTolerance={50}
        preventMovementUntilSwipeScrollTolerance
        selectedItem={currentSlide}
        onChange={updateCurrentSlide}
        autoPlay={props.autoPlay || false}
        renderIndicator={(onClickHandler, isSelected, index, label) => (
          <li
            className={isSelected ? 'dot selected' : 'dot'}
            onClick={onClickHandler}
            onKeyDown={onClickHandler}
            value={index}
            key={index}
            aria-label={`${label} ${index + 1}`}
          />
        )}
      >
        {props.children}
      </Carousel>
      {props.children.length > 1 ? (
        <>
          <button
            type="button"
            className="custom-control-arrow control-arrow control-prev"
            onClick={prev}
          >
            <span className="sr-only">{sliderPrevButtonText}</span>
          </button>
          <button
            type="button"
            className="custom-control-arrow control-arrow control-next"
            onClick={next}
          >
            <span className="sr-only">{sliderNextButtonText}</span>
          </button>
        </>
      ) : null}
    </div>
  );
};

export default CustomCarousel;
