import axios from 'axios';

const convert = require('xml-js');

export const penniesToPounds = (pennies: string): string =>
  (parseFloat(pennies?.replace(/,/g, '')) / 100).toFixed(2).toString() || '0';

export const getSharePrice = () =>
  axios
    .get(process.env.GATSBY_SHARE_PRICE_URL!, {
      headers: {
        'Content-Type': 'text/xml',
      },
    })
    .then(({ data, status }) => {
      if (data.StatusCode && data.StatusCode !== 200) {
        throw new Error(
          `Response status: ${status} | data.StatusCode: ${data.StatusCode} | ErrorMessage: ${data.ErrorMessage}`
        );
      }

      const jsonData = JSON.parse(convert.xml2json(data));
      const elements = jsonData?.elements[0];

      return {
        symbol: elements?.elements[0].elements[0].text,
        currentPrice: elements?.elements[4].elements[0].text,
        change: elements?.elements[5].elements[0].text,
        changeSign: elements?.elements[5].elements[0].text.trim().substring(0, 1),
        percentageChange: elements?.elements[6].elements[0].text,
      };
    })
    .catch((error) => {
      console.error(`Fetch error: ${error.message}`);

      return {};
    });
