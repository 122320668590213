import { LinkType } from './models';

export const getLinkType = (url: string): LinkType => {
  const type: LinkType = {
    isExternalLink: url?.charAt(0) !== '/',
    isMediaLink: !!url?.match(/\/media\//g),
  };

  return type;
};
