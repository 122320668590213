const parseFooterAddress = (address, isSmallMobile, isMobile, isTablet): string => {
  const parseMobileFooterAddress = (mobileAddress: string): string =>
    mobileAddress.replace('\n\n', '\r\r').replace(/[\n]/g, ' ').replace('\r\r', '\n\n');

  const parseTabletFooterAddress = (tabletAddress: string): string =>
    tabletAddress
      .replace(/(\n){2,}/g, '++')
      .replace(/[\n]/g, ' ')
      .replace(/[+]{2,}/g, '\n\n');

  if (isSmallMobile || isMobile) {
    return parseMobileFooterAddress(address);
  }
  if (isTablet) {
    return parseTabletFooterAddress(address);
  }

  return address;
};

export default parseFooterAddress;
