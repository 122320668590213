export const SHARE_PRICE_DELAY: number = 60 * 1000 * 60;
export const ALL: string = 'All';
export const MOBILE__DEVICE = 375;
export const TABLET_DEVICE = 768;
export const DESKTOP_DEVICE = 1200;
export const XLDESKTOP_DEVICE = 1600;
export const ARTICLES_PER_LOAD_DEFAULT: number = 12;
export const RESULTS_AND_PRESENTATIONS_NUMBER_OF_ARTICLES: number = 7;
export const POLICIES_PER_LOAD_DEFAULT: number = 10;
export const MEDIA_LANDING_NUMBER_OF_ARTICLES: number = 6;
export const FLOCKLER_SITE_ID = '17bf3dea1c20872d8872c629457b3487';
export const EVENTS_PER_PAGE = 2;
export const FIRST_LEVEL_PAGE_LEVEL = 3;
export const SECOND_LEVEL_PAGE_LEVEL = 4;
export const THIRD_LEVEL_PAGE_LEVEL = 5;
export const POPULAR_TOPICS_TABLET: number = 3;
export const MOST_VIEWED_TABLET: number = 3;
export const TAB: string = 'Tab';
export const RELATED_NEWS_DEFAULT_NUMBER: number = 3;
export const NEWS_AND_STORIES_DEFAULT_NUMBER: number = 6;
export const CASE_STUDY_SLIDER_DEFAULT_NUMBER: number = 4;
export const DEBOUNCE_TIME = 400;
export const LINKEDIN: string = 'Linkedin';
export const TWITTER: string = 'Twitter';
export const FACEBOOK: string = 'Facebook';
export const WHATSAPP: string = 'Whatsapp';
export const EMAIL: string = 'Email';
