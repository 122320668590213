import { createContext } from 'react';

import { PageContent } from '../@types/content/page';

const ScreenRecognitionContext = createContext({
  isSmallMobile: false,
  isMobile: false,
  isTablet: false,
  isDesktop: true,
  isLargeDesktop: true,
} as PageContent.ScreenResolutionType);

export default ScreenRecognitionContext;
