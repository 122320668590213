import React, { FC } from 'react';
import classnames from 'classnames';

import { Element } from 'layout';

import { ColProps } from './models';

const Col: FC<ColProps> = ({ sm, md, lg, xl, element, children, className, ...rest }) => {
  const prefix = 'col--';

  return (
    <Element
      element={element || 'div'}
      className={classnames('col', className, {
        [`${prefix}xl${xl}`]: xl,
        [`${prefix}lg${lg}`]: lg,
        [`${prefix}md${md}`]: md,
        [`${prefix}sm${sm}`]: sm,
      })}
      {...rest}
    >
      {children}
    </Element>
  );
};

export default Col;
