import React, { useContext } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import objectHash from 'object-hash';

import { Col, Container, Row } from 'layout';
import ExternalLink from 'common/ExternalLink/ExternalLink';
import InternalExternalLink from 'common/InternalExternalLink';
import { cutUrl } from 'utils/functions';
import parseFooterAddress from 'utils/parseFooterAddress';

import Icon from '../../common/icons/chevron-up.svg';
import XTwitter from '../../common/icons/x-twitter.svg';
import ScreenRecognitionContext from '../../contexts/screenRecognitionContext';
import { FooterProps } from './models';

import './Footer.scss';

const Footer = () => {
  const { footer }: FooterProps = useStaticQuery(graphql`
    query FooterQuery {
      footer {
        logo {
          ...FragmentSvgImage
        }
        logoAltText
        address
        copyrightText
        menuOneTitle
        quickLinks {
          ...FragmentLink
        }
        menuTwoTitle
        legalLinks {
          ...FragmentLink
        }
        followUsText
        socialMediaLinks {
          url
          icon
        }
        backToTop
      }
    }
  `);

  const {
    logo,
    logoAltText,
    address,
    copyrightText,
    menuOneTitle,
    quickLinks,
    menuTwoTitle,
    legalLinks,
    followUsText,
    socialMediaLinks,
    backToTop,
  } = footer;

  const { isSmallMobile, isMobile, isTablet, isDesktop, isLargeDesktop } =
    useContext(ScreenRecognitionContext);

  const parsedAddress = parseFooterAddress(address, isSmallMobile, isMobile, isTablet);

  const socialMediaLinksWithTitle = socialMediaLinks?.map((link) => ({
    ...link,
    title: link.icon[0].split('-')[0],
  }));

  const scrollToTopHandle = () => {
    document.documentElement.scrollTop = 0;
  };

  const urlRefined = cutUrl(logo.url);
  const QuickLinks = () => (
    <>
      {quickLinks?.map(({ name, url, target }) => (
        <li key={name} className="footer__links-item h6">
          <InternalExternalLink
            classNameLink="footer__links-links h6"
            url={url}
            title={name}
            target={target}
            hasIcon={false}
            isLink
            showTitle
          />
        </li>
      ))}
    </>
  );

  const LegalLinks = () => (
    <>
      {legalLinks?.map(({ name, url, target }) => (
        <li key={name} className="footer__legal-item">
          <InternalExternalLink
            classNameLink="footer__legal-links tag"
            url={url}
            title={name}
            target={target}
            hasIcon={false}
            isLink
            showTitle
          />
        </li>
      ))}
    </>
  );

  const SocialMediaLinksWithTitle = () => (
    <>
      {socialMediaLinksWithTitle?.map(({ url, icon, title }) => (
        <li key={icon[0]} className="footer__social-links-item">
          <ExternalLink
            className="footer__social-links-link"
            url={url}
            dataTestId={icon[0]}
            title={title}
          >
            {icon[0] === 'xtwitter' ? (
              <XTwitter className="footer__xtwitter" />
            ) : (
              <FontAwesomeIcon
                titleId={title}
                id={`${objectHash({ footerIconId: title })}`}
                aria-labelledby={title}
                className="fa"
                icon={['fab', icon[0] as IconName]}
                title={`${icon[0]} icon`}
              />
            )}
          </ExternalLink>
        </li>
      ))}
    </>
  );

  return (
    <footer data-testid="footer" className="footer">
      <Container className="footer__container">
        <Row className="footer__row-top">
          <Col xl="6" md="3" sm="12" className="footer__logo-container col--no-gutters">
            <Link className="footer__logo-link" to="/">
              <img src={urlRefined} className="footer__logo" alt={logoAltText} />
            </Link>
            {(isTablet || isDesktop || isLargeDesktop) && (
              <address className="footer__address tag">{parsedAddress}</address>
            )}
          </Col>
          <Col xl="3" md="4" sm="12" className="footer__link-container col--no-gutters">
            <h2 className="footer__links-title tag--bold">{menuOneTitle}</h2>
            <ul className="footer__links-list">
              <QuickLinks />
            </ul>
          </Col>
          <Col xl="3" md="4" sm="12" className="footer__legal-container col--no-gutters">
            <h2 className="footer__legal-title tag--bold">{menuTwoTitle}</h2>
            <ul className="footer__legal-list">
              <LegalLinks />
            </ul>
            <h2 className="footer__social-title tag">{followUsText}</h2>
            <ul className="footer__social-links">
              <SocialMediaLinksWithTitle />
            </ul>
          </Col>
          {(isSmallMobile || isMobile) && (
            <Col xl="0" md="0" sm="12" className="footer__col-address-mob col--no-gutters">
              <address className="footer__address tag">{parsedAddress}</address>
            </Col>
          )}
        </Row>
        <Row className="footer__row-bottom row--align-end">
          <Col xl="7" md="7" sm="7" className="col--no-gutters">
            <p className="footer__copyright-text breadcrumb">{copyrightText}</p>
          </Col>
          <Col xl="4" md="2" sm="5" className="col--no-gutters">
            <button
              className="footer__back-to-top breadcrumb"
              type="button"
              onClick={scrollToTopHandle}
            >
              {backToTop}
              <span className="footer__icon" aria-hidden="true">
                <Icon className="footer__icon-pic" aria-hidden="true" focusable="false" />
              </span>
            </button>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
