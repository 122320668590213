import React, { FC } from 'react';
import classnames from 'classnames';

import { Element } from 'layout';

import { RowProps } from './models';

const Row: FC<RowProps> = ({ element, children, className, ...rest }) => (
  <Element element={element || 'div'} className={classnames('row', className)} {...rest}>
    {children}
  </Element>
);

export default Row;
