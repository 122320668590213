import React, { FC } from 'react';
import { Link } from 'gatsby';

import {
  getNonHiddenElements,
  getResponsiveActiveLinkClassesByDepth,
  getResponsiveItemClassesByDepth,
  getResponsiveLinkClassesByDepth,
} from 'components/Navigation/utils';
import { isBrowser } from 'utils/browser';

import Arrow from '../../../../common/icons/arrow-right.svg';
import SubMenuResponsive from '../SubMenuResponsive';
import { ListMenuResponsiveProps } from './models';

import './ListMenuResponsive.scss';

const ListMenuResponsive: FC<ListMenuResponsiveProps> = ({
  depth,
  data,
  hasSubMenu,
  menuName,
  menuIndex,
  activeMenus,
  activeClasses,
  itemClickHandle,
  activePageClickHandle,
  backHandle,
  sharePriceLabelText,
  sharePriceLink,
  locationsButtonText,
  locationsLinks,
  parentPage,
}) => {
  const handleChangeAtribute = (e) => {
    const all = document.querySelectorAll('.nav__link');
    const ariaExpanded = e.currentTarget.getAttribute('aria-expanded');
    const value = ariaExpanded === 'false' ? 'true' : 'false';
    all.forEach((element) => {
      if (element.classList.contains('active-link-clicked')) return;
      element.setAttribute('aria-expanded', 'false');
    });
    e.currentTarget.setAttribute('aria-expanded', value);
  };

  const dataLayer = () => {
    if (!isBrowser() || !(window as any).dataLayer) return;
    if (depth <= 1) {
      (window as any).dataLayer.push({
        event: 'navigation',
        navigation_location: 'sidebar',
        primary_navigation: data.page[0].name,
      });
    } else {
      (window as any).dataLayer.push({
        event: 'navigation',
        navigation_location: 'sidebar',
        primary_navigation: parentPage,
        secondary_navigation: data.page[0].name,
      });
    }
  };

  return (
    <li className={getResponsiveItemClassesByDepth(depth)} data-testid="list-menu">
      {hasSubMenu ? (
        <Link
          partiallyActive
          activeClassName="nav-responsive__link--active"
          className={getResponsiveActiveLinkClassesByDepth(activeClasses, menuName, depth)}
          aria-expanded="false"
          to={data.page[0].url}
          state={{ activeClasses }}
          onClick={(e) => {
            e.preventDefault();
            handleChangeAtribute(e);
            itemClickHandle(menuName, depth);
            dataLayer();
          }}
        >
          <span className="nav-responsive__link-text">{data.page[0].name}</span>
          {hasSubMenu && (
            <span className="nav-responsive__arrow" aria-hidden="true">
              <Arrow className="nav-responsive__arrow-pic" alt="Decorative icon" />
            </span>
          )}
        </Link>
      ) : (
        <Link
          partiallyActive
          className={getResponsiveLinkClassesByDepth(depth)}
          activeClassName="nav-responsive__link--active"
          to={data.page[0].url}
          state={{ activeClasses }}
          onClick={(e) => {
            e.preventDefault();
            activePageClickHandle(e, data?.page[0].url);
            dataLayer();
          }}
        >
          {data.page[0].name}
        </Link>
      )}
      {hasSubMenu && (
        <SubMenuResponsive
          depth={depth}
          data={getNonHiddenElements(data.childElements)}
          toggle={activeMenus.includes(menuName)}
          menuIndex={menuIndex}
          mainPage={data}
          activeMenus={activeMenus}
          activeClasses={activeClasses}
          itemClickHandle={itemClickHandle}
          activePageClickHandle={activePageClickHandle}
          backHandle={backHandle}
          sharePriceLabelText={sharePriceLabelText}
          sharePriceLink={sharePriceLink}
          locationsButtonText={locationsButtonText}
          locationsLinks={locationsLinks}
        />
      )}
    </li>
  );
};

export default ListMenuResponsive;
