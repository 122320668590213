import React, { createContext, useContext, useEffect, useState } from 'react';
import algoliasearch from 'algoliasearch/lite';

export const AlgoliaContext = createContext({});

export const useAlgoliaContext = () => useContext(AlgoliaContext);

export const AlgoliaContextProvider = ({ children }) => {
  const [searchClient, setSearchClient] = useState({});

  useEffect(() => {
    const client = algoliasearch(
      `${process.env.GATSBY_ALGOLIA_APP_ID}`,
      `${process.env.GATSBY_ALGOLIA_SEARCH_KEY}`
    );

    setSearchClient(client);
  }, []);

  return <AlgoliaContext.Provider value={searchClient}>{children}</AlgoliaContext.Provider>;
};
