const getQuery = () => {
  if (typeof window !== 'undefined') {
    return new URLSearchParams(window.location.search);
  }

  return new URLSearchParams();
};

export const getQueryStringVal = (key: string): string => {
  try {
    const stringToReturn = decodeURI(getQuery().get(key) || '');

    return stringToReturn && stringToReturn !== 'null' ? stringToReturn : '';
  } catch {
    const stringToReturn = decodeURI(encodeURI(getQuery().get(key) || ''));

    return stringToReturn && stringToReturn !== 'null' ? stringToReturn : '';
  }
};

export const setQueryStringVal = (key: string, val: string) => {
  const queryRes = getQuery();

  if (val.trim() !== '') {
    queryRes.set(key, val);
  } else {
    queryRes.delete(key);
  }

  // This check is necessary if using the hook with Gatsby
  if (typeof window === 'undefined') return;

  const { protocol, pathname, host } = window.location;
  const newUrl = `${protocol}//${host}${pathname}?${queryRes.toString()}`;
  window.history.pushState({}, '', newUrl);
};
