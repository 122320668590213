import React, { FC, useContext, useState } from 'react';
import { navigate } from '@reach/router';

import ModalContext from '../../contexts/modalContext';
import Search from '../icons/search.svg';
import { SearchBoxComponentProps } from './models';

const SearchBoxComponent: FC<SearchBoxComponentProps> = ({
  searchPlaceholder,
  searchButtonText,
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const { onCloseModal } = useContext(ModalContext);

  const goToSearchPage = (searchQuery) => {
    const trimSearchValue = searchQuery.trim();
    if (trimSearchValue === '') return;
    navigate(encodeURI(`/search/?q=${trimSearchValue}`));
    setInputValue('');
  };

  const handleSearch = (e): void => {
    e.preventDefault();
    inputValue && goToSearchPage(inputValue);
    inputValue && inputValue.trim() !== '' && onCloseModal();
  };

  const handleKeyPress = (e): void => {
    if (e.key === 'Enter') {
      handleSearch(e);
    }
  };

  const handleChangeInput = (e): void => setInputValue(e.target.value);

  const handleIOSKeyboard = () => {
    const closeBtnFocus = document.querySelector('.search-modal__responsive-close-btn');

    if (!closeBtnFocus) return;

    (closeBtnFocus as HTMLElement).focus();
    (closeBtnFocus as HTMLElement).blur();
  };

  return (
    <>
      <input
        type="search"
        className="search-input__input search-input__input-modal"
        placeholder={searchPlaceholder}
        aria-label={searchPlaceholder}
        value={inputValue}
        onChange={handleChangeInput}
        onKeyPress={handleKeyPress}
        onBlur={handleIOSKeyboard}
      />
      <button
        type="submit"
        className="search-input__btn"
        onClick={handleSearch}
        aria-label={searchButtonText}
      >
        <span className="search-input__btn-text" aria-hidden="true">
          {searchButtonText}
        </span>
        <span className="search-input__btn-icon">
          <Search aria-hidden="true" focusable="false" className="search-input__btn-pic" />
        </span>
      </button>
    </>
  );
};

export default SearchBoxComponent;
