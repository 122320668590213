import React, { FC, useMemo } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import { getLinkType } from 'utils/services/linkTypeService';

import Download from '../icons/arrow-down.svg';
import Arrow from '../icons/arrow-right.svg';
import { InternalExternalLinkProps } from './models';

const InternalExternalLink: FC<InternalExternalLinkProps> = ({
  children,
  classNameLink,
  classNameTitle,
  url,
  title,
  target,
  ariaLabel,
  onMouseEnter,
  onMouseLeave,
  tabIndex,
  iconClass,
  hasIcon,
  isLink,
  showTitle,
}) => {
  const { isExternalLink, isMediaLink } = useMemo(() => getLinkType(url), [url]);

  const InternalLink = () => (
    <>
      {isLink ? (
        <Link
          to={url}
          className={classNameLink}
          aria-label={ariaLabel === null ? 'true' : ariaLabel}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          tabIndex={tabIndex}
        >
          {showTitle ? (
            <span className={classNameTitle || ''}>{title || ''}</span>
          ) : (
            <span className={classNames('sr-only', { classNameTitle })}>{title || ''}</span>
          )}
          {children}
          {hasIcon ? (
            <Arrow className={iconClass || ''} aria-hidden="true" focusable="false" />
          ) : null}
        </Link>
      ) : (
        <span
          className={classNameLink}
          aria-label={ariaLabel === null ? '' : ariaLabel}
          aria-hidden="true"
        >
          {showTitle ? (
            <span className={classNameTitle || ''}>{title || ''}</span>
          ) : (
            <span className={classNames('sr-only', { classNameTitle })}>{title || ''}</span>
          )}
          {children}
          {hasIcon ? (
            <Arrow className={iconClass || ''} aria-hidden="true" focusable="false" />
          ) : null}
        </span>
      )}
    </>
  );

  const ExternalLink = () => (
    <>
      {isLink ? (
        <a
          href={url}
          rel="noreferrer noopener"
          className={classNameLink}
          target={target}
          aria-label={ariaLabel === null ? 'true' : ariaLabel}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          tabIndex={tabIndex}
        >
          {showTitle ? (
            <span className={classNameTitle || ''}>{title || ''}</span>
          ) : (
            <span className={classNames('sr-only', { classNameTitle })}>{title || ''}</span>
          )}
          {children}
          {hasIcon ? (
            <Download
              className={`external ${iconClass || ''}`}
              aria-hidden="true"
              focusable="false"
            />
          ) : null}
        </a>
      ) : (
        <span className={classNameLink} aria-hidden="true">
          {showTitle ? (
            <span className={classNameTitle || ''}>{title || ''}</span>
          ) : (
            <span className={classNames('sr-only', { classNameTitle })}>{title || ''}</span>
          )}
          {children}
          {hasIcon ? (
            <Download
              className={`external ${iconClass || ''}`}
              aria-hidden="true"
              focusable="false"
            />
          ) : null}
        </span>
      )}
    </>
  );

  const MediaLink = () => (
    <>
      {isLink ? (
        <a
          className={classNameLink}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={ariaLabel === null ? 'true' : ariaLabel}
          tabIndex={tabIndex}
        >
          {showTitle ? (
            <span className={classNameTitle || ''}>{title || ''}</span>
          ) : (
            <span className={classNames('sr-only', { classNameTitle })}>{title || ''}</span>
          )}
          {children}
          {hasIcon ? (
            <Download className={iconClass || ''} aria-hidden="true" focusable="false" />
          ) : null}
        </a>
      ) : (
        <span className={classNameLink} aria-hidden="true">
          {showTitle ? (
            <span className={classNameTitle || ''}>{title || ''}</span>
          ) : (
            <span className={classNames('sr-only', { classNameTitle })}>{title || ''}</span>
          )}
          {children}
          {hasIcon ? (
            <Download className={iconClass || ''} aria-hidden="true" focusable="false" />
          ) : null}
        </span>
      )}
    </>
  );

  const LinkType = () => {
    if (isExternalLink) return <ExternalLink />;
    if (isMediaLink) return <MediaLink />;

    return <InternalLink />;
  };

  return <LinkType />;
};

export default InternalExternalLink;
