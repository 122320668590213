import React, { useContext, useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import ScreenRecognitionContext from '../../contexts/screenRecognitionContext';
import { NavigationContentType } from './models';
import NavigationRenderer from './NavigationRenderer';
import { getNonHiddenElements } from './utils';

import './Navigation.scss';

const Navigation = () => {
  const { navigation }: NavigationContentType = useStaticQuery(graphql`
    query NavigationQuery {
      navigation {
        logo {
          ...FragmentSvgImage
        }
        logoWhite {
          ...FragmentSvgImage
        }
        logoAltText
        skipToContentText
        navigationSectionText
        menuLabel
        closeMenuLabel
        searchButtonText
        closeSearchButtonText
        locationsButtonText
        sharePriceLabelText
        sharePriceLink {
          name
          icon
          url
        }
        navigationTree {
          ...navigationElementFragment
        }
        locationsLinks {
          ...FragmentLink
        }
      }
    }
  `);

  const {
    logo,
    logoWhite,
    logoAltText,
    skipToContentText,
    navigationSectionText,
    menuLabel,
    closeMenuLabel,
    searchButtonText,
    closeSearchButtonText,
    locationsButtonText,
    sharePriceLabelText,
    sharePriceLink,
    navigationTree,
    locationsLinks,
  } = navigation;

  const { isDesktop, isLargeDesktop } = useContext(ScreenRecognitionContext);
  const headerRef = useRef<any>(null);

  const navigationItems = getNonHiddenElements(navigationTree);

  return (
    <header className="header" ref={headerRef}>
      <NavigationRenderer
        navigationItems={navigationItems}
        logo={logo}
        logoWhite={logoWhite}
        logoAltText={logoAltText}
        skipToContentText={skipToContentText}
        navigationSectionText={navigationSectionText}
        menuLabel={menuLabel}
        closeMenuLabel={closeMenuLabel}
        searchButtonText={searchButtonText}
        closeSearchButtonText={closeSearchButtonText}
        locationsButtonText={locationsButtonText}
        sharePriceLabelText={sharePriceLabelText}
        sharePriceLink={sharePriceLink}
        isLargeDesktop={isLargeDesktop}
        isDesktop={isDesktop}
        headerElement={headerRef}
        locationsLinks={locationsLinks}
      />
    </header>
  );
};

export default Navigation;
