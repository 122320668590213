import React, { FC } from 'react';
import { Link } from 'gatsby';

import NavigationBottom from 'components/Navigation/NavigationBottom';
import { getNonHiddenElements } from 'components/Navigation/utils';

import Arrow from '../../../../common/icons/arrow-right.svg';
import ListMenuResponsive from '../ListMenuResponsive';
import { SubMenuResponsiveProps } from './models';

import './SubMenuResponsive.scss';

const SubMenuResponsive: FC<SubMenuResponsiveProps> = ({
  depth,
  data,
  toggle,
  menuIndex,
  mainPage,
  activeMenus,
  activeClasses,
  itemClickHandle,
  activePageClickHandle,
  backHandle,
  sharePriceLabelText,
  sharePriceLink,
  locationsButtonText,
  locationsLinks,
}) => {
  if (!toggle) {
    return null;
  }

  const depthVar = depth + 1;

  return (
    <div
      className={
        depthVar === 2
          ? 'nav-responsive__list-wraper nav-responsive__list-wraper--second'
          : 'nav-responsive__list-wraper nav-responsive__list-wraper--third'
      }
    >
      <button className="nav-responsive__back-btn" type="button" onClick={backHandle}>
        <Arrow className="nav-responsive__back-icon" alt="Decorative icon" aria-hidden="true" />
        <span className="nav-responsive__back-text breadcrumb">Back</span>
      </button>
      <ul
        className={
          depthVar === 2
            ? 'nav-responsive__list nav-responsive__list--second'
            : 'nav-responsive__list nav-responsive__list--third'
        }
        data-testid="sub-menu"
      >
        {depthVar !== 1 && (
          <li className="nav-responsive__section">
            <Link
              className="nav-responsive__title-list-link"
              to={mainPage.page[0].url}
              onClick={(e) => {
                e.preventDefault();
                activePageClickHandle(e, mainPage?.page[0].url);
              }}
            >
              <span className="nav-responsive__title-list-title h5">{mainPage.page[0].name}</span>
              <span className="nav-responsive__arrow" aria-hidden="true">
                <Arrow className="nav-responsive__arrow-pic" alt="Decorative icon" />
              </span>
            </Link>
          </li>
        )}
        {getNonHiddenElements(data).map((menu, index) => {
          const menuName = `sidebar-submenu-${depthVar}-${menuIndex}-${index}`;

          return (
            <ListMenuResponsive
              activeClasses={activeClasses}
              depth={depthVar}
              data={menu}
              hasSubMenu={menu.childElements?.length > 0}
              menuName={menuName}
              key={menuName}
              menuIndex={index}
              activeMenus={activeMenus}
              itemClickHandle={itemClickHandle}
              activePageClickHandle={activePageClickHandle}
              backHandle={backHandle}
              sharePriceLabelText={sharePriceLabelText}
              sharePriceLink={sharePriceLink}
              locationsButtonText={locationsButtonText}
              locationsLinks={locationsLinks}
              parentPage={mainPage?.page[0].name}
            />
          );
        })}
      </ul>
      <NavigationBottom
        isResponsive
        locationsButtonText={locationsButtonText}
        sharePriceLabelText={sharePriceLabelText}
        sharePriceLink={sharePriceLink}
        locationsLinks={locationsLinks}
      />
    </div>
  );
};

export default SubMenuResponsive;
