import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Link, navigate } from 'gatsby';
import classNames from 'classnames';

import Icon from 'common/icons/chevron-up.svg';
import { cutUrl } from 'utils/functions';

import Search from '../../../common/icons/search.svg';
import ModalContext from '../../../contexts/modalContext';
import NavigationBottom from '../NavigationBottom';
import { activeMenusHandle, constants, getNonHiddenElements } from '../utils';
import ListMenu from './ListMenu';
import { DesktopNavigationProps } from './models';

import './DesktopNavigation.scss';

const DesktopNavigation: FC<DesktopNavigationProps> = ({
  items,
  logo,
  logoAltText,
  skipToContentText,
  navigationSectionText,
  searchButtonText,
  closeSearchButtonText,
  locationsButtonText,
  sharePriceLabelText,
  sharePriceLink,
  headerElement,
  locationsLinks,
}) => {
  const ref = useRef<HTMLElement>(null);

  const [activeMenus, setActiveMenus] = useState<string[]>([]);
  const [activeClasses, setActiveClasses] = useState<string[]>([]);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [styleFirstVerticalLine, setStyleFirstVerticalLine] = useState<object>({});
  const [styleSecondVerticalLine, setStyleSecondVerticalLine] = useState<object>({});
  const [styleThirdVerticalLine, setStyleThirdVerticalLine] = useState<object>({});
  const [secondLevelActive, setSecondLevelActive] = useState<boolean>(false);
  const [thirdLevelActive, setThirdLevelActive] = useState<boolean>(false);
  const { isOpen, onOpenModal, onCloseModal } = useContext(ModalContext);

  const activeLinkClicked = '.active-link-clicked';
  const screenHeight = window.innerHeight;

  const url = cutUrl(logo.url);

  // line which follow link on hover

  const setTopAndHeight = (element: HTMLElement, stateOfVerticalLine) => {
    const top = element?.getBoundingClientRect().top;
    const height = element?.getBoundingClientRect().height;

    stateOfVerticalLine({
      top: `${top}px`,
      height: `${height}px`,
    });
  };

  const setDefaultPosition = useCallback(
    (stateOfVerticalLine, linkLevel, clickedActiveLink, level) => {
      const links = document.querySelectorAll(linkLevel);
      const convertedArray = Array.prototype.slice.call(links);
      const activeLink = convertedArray.filter((link) =>
        link.classList.contains('nav__link--active')
      );
      const clickedLink = document.querySelectorAll(clickedActiveLink);
      const convertedArrayClickedLink = Array.prototype.slice.call(clickedLink);

      if (activeLink.length) {
        setTopAndHeight(activeLink[0], stateOfVerticalLine);
      } else {
        stateOfVerticalLine({
          top: `${220}px`,
          height: `${0}px`,
        });
      }

      if (convertedArrayClickedLink.length) {
        setTopAndHeight(convertedArrayClickedLink[level], stateOfVerticalLine);
      }
    },
    []
  );

  const onMouseOverHandle = (e) => {
    if (e.currentTarget.className.includes('nav__link--first')) {
      const { top } = e.currentTarget.getBoundingClientRect();
      const { height } = e.currentTarget.getBoundingClientRect();

      const scroll = headerElement.current?.scrollTop;

      const topPosition = top + scroll;

      setStyleFirstVerticalLine({
        top: `${topPosition}px`,
        height: `${height}px`,
      });
    }

    if (e.currentTarget.className.includes('nav__link--second')) {
      const { top } = e.currentTarget.getBoundingClientRect();
      const { height } = e.currentTarget.getBoundingClientRect();

      setStyleSecondVerticalLine({
        top: `${top}px`,
        height: `${height}px`,
      });
    }

    if (e.currentTarget.className.includes('nav__link--third')) {
      const { top } = e.currentTarget.getBoundingClientRect();
      const { height } = e.currentTarget.getBoundingClientRect();

      setStyleThirdVerticalLine({
        top: `${top}px`,
        height: `${height}px`,
      });
    }
  };

  const onMouseLeaveHandle = () => {
    setDefaultPosition(setStyleFirstVerticalLine, constants.firstLink, activeLinkClicked, 0);

    if (activeMenus.length > 0) {
      setTimeout(() => {
        setDefaultPosition(setStyleSecondVerticalLine, constants.secondLink, activeLinkClicked, 1);
      }, 500);
    }

    if (activeMenus.length > 1) {
      setTimeout(() => {
        setDefaultPosition(setStyleThirdVerticalLine, constants.thirdLink, activeLinkClicked, 2);
      }, 500);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setDefaultPosition(setStyleFirstVerticalLine, constants.firstLink, activeLinkClicked, 0);
    }, 300);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setDefaultPosition(setStyleSecondVerticalLine, constants.secondLink, activeLinkClicked, 1);
    }, 300);
  }, [secondLevelActive]);

  useEffect(() => {
    setTimeout(() => {
      setDefaultPosition(setStyleThirdVerticalLine, constants.thirdLink, activeLinkClicked, 2);
    }, 300);
  }, [thirdLevelActive]);

  // accessibility - logic for tab on keyboard

  const focusHandle = useCallback(
    (e) => {
      if (e.target.className.includes('nav__link--first')) {
        setActiveMenus([]);
        setActiveClasses([]);
      }

      if (e.target.className.includes('nav__link--second')) {
        const newActiveMenus = [...activeMenus];

        if (newActiveMenus.length > 1) {
          newActiveMenus.pop();
          setActiveMenus(newActiveMenus);
          setActiveClasses(newActiveMenus);
        }
      }
    },
    [activeMenus]
  );

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
        setActiveMenus([]);
        setActiveClasses([]);
        setDefaultPosition(setStyleFirstVerticalLine, constants.firstLink, activeLinkClicked, 0);
      }
    };

    document.addEventListener('click', checkIfClickedOutside);

    return () => {
      document.removeEventListener('click', checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  const itemClickHandle = (menuName, depth) => {
    let newActiveMenus = [...activeMenus];
    setActiveClasses(newActiveMenus);

    newActiveMenus = activeMenusHandle(newActiveMenus, menuName, depth);
    const index = newActiveMenus.indexOf(menuName);

    if (newActiveMenus.length === 0 || index > -1) {
      setSecondLevelActive(false);
    }

    if (newActiveMenus.length === 1 && index > -1) {
      setSecondLevelActive(!secondLevelActive);
      setThirdLevelActive(!thirdLevelActive);
    }

    if (newActiveMenus.length === 2 && index > -1) {
      setThirdLevelActive(!thirdLevelActive);
    }

    setActiveMenus(newActiveMenus);

    if (newActiveMenus.length > 0) {
      setIsMenuOpen(true);
    }
  };

  const activePageClickHandle = (e, path) => {
    if (e.currentTarget.href === window.location.href) {
      setActiveMenus([]);
      setActiveClasses([]);
      setIsMenuOpen(false);
    } else {
      navigate(path);
    }
  };

  return (
    <div
      className={classNames('header__container', {
        'header__container--large-height': screenHeight >= 950,
        'header__container--normal-height': screenHeight < 950 && screenHeight >= 810,
        'header__container--small-height': screenHeight < 810,
      })}
      data-testid="navigation"
    >
      <span className="header__vertical-line" aria-hidden="true" style={styleFirstVerticalLine} />
      <CSSTransition
        in={activeMenus.length > 0}
        timeout={500}
        classNames="show-nav"
        mountOnEnter
        unmountOnExit
      >
        <div className="header__overlay" />
      </CSSTransition>
      <a href="#main" className="header__skip">
        {skipToContentText}
        <span className="header__icon" aria-hidden="true">
          <Icon className="header__icon-pic" aria-hidden="true" focusable="false" />
        </span>
      </a>
      <div className="header__top">
        <Link className="header__logo-link" to="/">
          <img src={url} alt={logoAltText} className="header__logo" />
        </Link>
        {!isOpen ? (
          <button
            type="button"
            className="header__search-btn"
            aria-expanded="false"
            aria-controls="modal"
            onClick={() => {
              onOpenModal();
            }}
          >
            <span className="header__search-text">{searchButtonText}</span>
            <span className="header__search-icon" aria-hidden="true">
              <Search className="header__search-pic" alt={searchButtonText} />
            </span>
          </button>
        ) : (
          <button
            type="button"
            className="header__search-btn header__search-btn-close"
            aria-expanded="true"
            aria-controls="modal"
            onClick={() => {
              onCloseModal();
            }}
          >
            <span className="header__search-text">{closeSearchButtonText}</span>
          </button>
        )}
      </div>
      <nav className="nav" role="navigation" ref={ref} onMouseLeave={onMouseLeaveHandle}>
        <ul className="nav__list nav__list--first" data-testid="desktop-navigation">
          {getNonHiddenElements(items).map((menu, index) => {
            const depth = 1;
            const menuName = `sidebar-menu-${depth}-${index}`;

            return (
              <ListMenu
                navigationSectionText={navigationSectionText}
                activeClasses={activeClasses}
                depth={depth}
                data={menu}
                hasSubMenu={menu.childElements?.length > 0}
                menuName={menuName}
                key={menuName}
                menuIndex={index}
                activeMenus={activeMenus}
                itemClickHandle={itemClickHandle}
                activePageClickHandle={activePageClickHandle}
                styleSecondVerticalLine={styleSecondVerticalLine}
                styleThirdVerticalLine={styleThirdVerticalLine}
                focusHandle={focusHandle}
                onMouseOverHandle={onMouseOverHandle}
              />
            );
          })}
        </ul>
      </nav>
      <NavigationBottom
        isResponsive={false}
        locationsButtonText={locationsButtonText}
        sharePriceLabelText={sharePriceLabelText}
        sharePriceLink={sharePriceLink}
        locationsLinks={locationsLinks}
      />
    </div>
  );
};

export default DesktopNavigation;
