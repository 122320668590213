import React, { createContext, useContext, useEffect, useState } from 'react';
import useInterval from 'hooks/useInterval';

import { SHARE_PRICE_DELAY } from 'utils/constants';
import { getSharePrice } from 'utils/services/sharePriceService';

export const SharePriceContext = createContext({
  data: {
    currentPrice: '',
    change: '',
    changeSign: '',
    percentageChange: '',
  },
  error: '',
});

export const useSharePriceContext = () => useContext(SharePriceContext);

export const SharePriceContextProvider = ({ children }) => {
  const [data, setData]: any = useState({});
  const [error, setError] = useState('');

  const handleSharePriceResponse = (response) => {
    if (Object.keys(response).length === 0) {
      setError('Unable to fetch share price xml data');
      setData(null);
    } else {
      setData(response);
      setError('');
    }
  };

  useEffect(() => {
    getSharePrice().then((response) => {
      handleSharePriceResponse(response);
    });
  }, []);

  useInterval(() => {
    getSharePrice().then((response) => {
      handleSharePriceResponse(response);
    });
  }, SHARE_PRICE_DELAY);

  return (
    <SharePriceContext.Provider value={{ data, error }}>{children}</SharePriceContext.Provider>
  );
};
