import React, { FC, useContext } from 'react';
import { Link } from 'gatsby';

import {
  getActiveLinkClassesByDepth,
  getItemClassesByDepth,
  getLinkClassesByDepth,
  getNonHiddenElements,
} from 'components/Navigation/utils';
import { handleChangeAtribute } from 'utils/accessibility';
import { isBrowser } from 'utils/browser';

import Arrow from '../../../../common/icons/arrow-right.svg';
import ModalContext from '../../../../contexts/modalContext';
import SubMenu from '../SubMenu';
import { ListMenuProps } from './models';

import './ListMenu.scss';

const ListMenu: FC<ListMenuProps> = ({
  navigationSectionText,
  depth,
  data,
  hasSubMenu,
  menuName,
  menuIndex,
  activeMenus,
  activeClasses,
  itemClickHandle,
  activePageClickHandle,
  styleSecondVerticalLine,
  styleThirdVerticalLine,
  focusHandle,
  onMouseOverHandle,
  parentPage,
}) => {
  const itemClasses = getItemClassesByDepth(depth);
  const activeLinkClasses = getActiveLinkClassesByDepth(activeClasses, menuName, depth);
  const linkClasses = getLinkClassesByDepth(depth);
  const { onCloseModal } = useContext(ModalContext);

  const dataLayer = () => {
    if (!isBrowser() || !(window as any).dataLayer) return;
    if (depth <= 1) {
      (window as any).dataLayer.push({
        event: 'navigation',
        navigation_location: 'sidebar',
        primary_navigation: data.page[0].name,
      });
    } else {
      (window as any).dataLayer.push({
        event: 'navigation',
        navigation_location: 'sidebar',
        primary_navigation: parentPage,
        secondary_navigation: data.page[0].name,
      });
    }
  };

  return (
    <li className={itemClasses} data-testid="list-menu">
      <div className="nav__item-container">
        {hasSubMenu ? (
          <Link
            partiallyActive
            activeClassName="nav__link--active"
            className={activeLinkClasses}
            aria-expanded="false"
            to={data.page[0].url}
            state={{ activeClasses }}
            onClick={(e) => {
              e.preventDefault();
              onCloseModal();
              handleChangeAtribute(e, 'nav__link', 'active-link-clicked');
              itemClickHandle(menuName, depth);
              dataLayer();
            }}
            onFocus={focusHandle}
            onMouseOver={onMouseOverHandle}
          >
            <span className="nav__link-text">{data.page[0].name}</span>
            {hasSubMenu && depth !== 1 && (
              <span className="nav__arrow" aria-hidden="true">
                <Arrow alt="Decorative icon" className="nav__arrow-pic" />
              </span>
            )}
          </Link>
        ) : (
          <Link
            partiallyActive
            className={linkClasses}
            activeClassName="nav__link--active"
            to={data.page[0].url}
            state={{ activeClasses }}
            onClick={(e) => {
              e.preventDefault();
              onCloseModal();
              activePageClickHandle(e, data.page[0].url);
              dataLayer();
            }}
            onFocus={focusHandle}
            onMouseEnter={onMouseOverHandle}
          >
            {data.page[0].name}
          </Link>
        )}
      </div>
      {hasSubMenu && (
        <SubMenu
          navigationSectionText={navigationSectionText}
          depth={depth}
          data={getNonHiddenElements(data.childElements)}
          toggle={activeMenus.includes(menuName)}
          menuIndex={menuIndex}
          mainPage={data}
          activeMenus={activeMenus}
          activeClasses={activeClasses}
          itemClickHandle={itemClickHandle}
          activePageClickHandle={activePageClickHandle}
          styleSecondVerticalLine={styleSecondVerticalLine}
          styleThirdVerticalLine={styleThirdVerticalLine}
          focusHandle={focusHandle}
          onMouseOverHandle={onMouseOverHandle}
        />
      )}
    </li>
  );
};

export default ListMenu;
